















































import { PercentCondition } from '@/includes/logic/FaceControl/FaceControlConditions'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({})
export default class PercentConditionView extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!:PercentCondition

  isActive(state: any) {
    if (this.model.mode === state) {
      return 'rgba(var(--a-primary), 1)'
    } else {
      return ''
    }
  }
}
